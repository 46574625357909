import React from 'react';
import Menu from './menu.js';
import Board from './board.js';
import MessageBox from './messageBox.js';
import Notification from './notification.js';
import ActionWizzard from './actionMainWizzard.js';
import ActionRotationWizzard from './actionRotationWizzard.js';
import ActionInsertPushWizzard from './actionInsertPushWizzard.js';
import './index.scss';
import { withTranslation } from 'react-i18next';
import i18n from './i18n';


//TODO: no wrong turns! Do not allow to do nothing.
class Game extends React.Component {
	constructor (props) {
		super(props);
		let initialBoard = null;
		if (props.initFields!==undefined){
			console.log('Init: '+props.initFields);	
			initialBoard = this.convertFrom(props.initFields, props.initRotations);
		}
		else if (props.history){
			initialBoard = props.history;
		}
		else {
			console.log('Use default fallback');
			initialBoard = this.convertFrom(
				'_____ _____ _mmm_ _____ _____', 
				'^^^^^ ^^^^^ ^^^^^ ^^^^^ ^^^^^'
			);
		}

		this.state = {
			history: initialBoard,

			squares: initialBoard[0].squares.slice(),
			rotation: initialBoard[0].rotation.slice(),

			totalAnimals: 5,
			//the different turn options a player can do 
			//(some can be combined with others)
			selectDone: false,
			selectAllowed: true,
			deselectAllowed: false,
			insertDone: false,
			insertAllowed: false,
			pushDone: false,
			pushAllowed: false,
			moveDone: false,
			moveAllowed: false,
			//FIXME: clean up duplicated rotate (deprecated) <=> rotation
			rotateDone: false,
			rotateAllowed: false,
			wizzard: null,
			removeDone: false,
			removeAllowed: false,
			switchPlayerAllowed: false,

			winnerIs: null,
			selectedCell: -1,
			selectedHistory: 0,
			selectedCellBoundary: null,
			
			clearHistoryAction: null,

			rhinoIsNext: true,

			//remote game id (to share with someone else or to use joining an open game session)
			remoteGameId: props.remoteGameId,
			//the own remote session id to validate against server
			remoteSessionId: props.remoteSessionId,
			//to say who you are: rhino or elephant; rhino is master (creates a game), elephant is slave (joins a game)
			remoteIAm: props.remoteIAm,

			remoteReloadTimer: null,
		};

		//TODO: read session from session storage?
		if (props.remoteGameId && !props.remoteSessionId){
			this.joinGame(props.remoteGameId);
		}
		if (props.remoteGameId){
			this.loadRemoteGameState();
		}
	}
	
	/**
	 * creates a "history" / initial state for the board from a simple string
	 * @param {String} minifiedFields a string showing 25 chars for the 25 cells/fields types; m=mountain, e=elephant, r=rhino, _=empty; spaces will be ignored 
	 * @param {String} minifiedOrientation a string showing 25 chars for the 25 cells/fields orientations; ^=north, v=south, <=west, >=east; spaces will be ignored
	 */
	convertFrom(minifiedFields, minifiedOrientation){
		let squares = [];
		let rotation = [];
		let fields = minifiedFields.replace(/\s/g,'');
		let rotations = minifiedOrientation.replace(/\s/g,'');
		if (fields.length!==25 || rotations.length!==25){
			throw new Error("lengths must be 25 characters, but was " + fields + " length: "+ fields.length + " and "+ rotations + " length: " + rotations.length);
		}
		const mapping = {
			'm': 'mountain',
			'r': 'rhino',
			'e': 'elephant',
			'_': 'empty',
		};
		const mappingRotation = {
			'^': 'north',
			'v': 'south',
			'>': 'east',
			'<': 'west',
		};
		
		for (let i = 0; i < fields.length; i++) {
			squares.push(mapping[fields.charAt(i)]);
		}
		for (let i = 0; i < rotations.length; i++) {
			rotation.push(mappingRotation[rotations.charAt(i)]);
		}
		return [{
			squares: squares,
			rotation: rotation,
		}];
	}

	rhinoChar = '\u{1F98F}'; 
	elephantChar = '\u{1f418}';

	changeLanguage(lng) {
		i18n.changeLanguage(lng);
	}

	render() {
		document.title = i18n.t('game.title');
		let status = document.title;
		
		if (this.state.remoteGameId){
			if (this.state.remoteIAm==='rhino'){
				if (this.state.rhinoIsNext){
					status = i18n.t('game.remote.itsMyTurn', {playerChar: this.rhinoChar} );
				}
				else{
					status = i18n.t('game.remote.waitingForOtherPlayer', {playerChar: this.elephantChar} );
				}
			}
			else if (this.state.remoteIAm==='elephant'){
				if (!this.state.rhinoIsNext){
					status = i18n.t('game.remote.itsMyTurn', {playerChar: this.elephantChar} );
				}
				else{
					status = i18n.t('game.remote.waitingForOtherPlayer', {playerChar: this.rhinoChar} );
				}
			}
		}
		else{
			status = i18n.t('game.turn', {
				player: this.state.rhinoIsNext ? i18n.t('game.rhino') : i18n.t('game.elephant'), 
				playerChar: this.state.rhinoIsNext ? this.rhinoChar : this.elephantChar
			});
		}

		let statusTurnClass = this.state.rhinoIsNext?'rhino':'elephant';
		if (this.state.winnerIs){
			status = i18n.t([`end.winnerIs.${this.state.winnerIs}`, 'end.winnerIs.unknown']);
			statusTurnClass = statusTurnClass + ' winner';
			document.title=status;
		}

		console.log('history.length: ' + this.state.history.length);
		const moves = this.state.history.map((step, move) => {
			const actionId = 'history-'+move;
			const highlight = move===this.state.selectedHistory?'highlight':'';
			return (
				<button className={`historyButtons ${highlight}`}
					data-action={actionId}
					key={move} aria-label={i18n.t('history.selectStep')} 
					onClick={() => this.jumpTo(move)}>
					<span>{i18n.t('history.step', {count: move})}</span>
				</button>
			);
		});
		
		const numberOfRhinosInGame = this.state.squares.filter(item => item==='rhino').length;
		const numberOfElephantsInGame = this.state.squares.filter(item => item==='elephant').length;

		const rhinosLeft = this.state.totalAnimals - numberOfRhinosInGame;
		const elephantsLeft = this.state.totalAnimals - numberOfElephantsInGame;
		const hasWarning = this.state.currentWarning !== '' && this.state.currentWarning !== undefined && this.state.currentWarning !== null;
		const showWarningMessage = hasWarning ? 'showWarning' : 'hide';
		const gameEndStatus = this.state.winnerIs?this.state.winnerIs:'no-winner';
		
		const actionStyle = {
			left: this.state.selectedCellBoundary?this.state.selectedCellBoundary.left-79:0,
			top: this.state.selectedCellBoundary?this.state.selectedCellBoundary.top-79:0,
		};
		const actions = this.getAvailableActions(this.state.selectedCell, this.state.squares, this.state.rotation, this.getCurrentPlayer());
		const focusCell = this.state.selectedCell!==-1?'darken':'hide';

		return (
		<div className={`${this.state.winnerIs} game`}>
			
			<div>
				<div className='header'>
					<Menu 
					actions={{
						'resetGame': {
							'title': i18n.t('menu.resetGame'),
							'action': ()=>this.resetGame(),
						},
						'divider1':'divider',
						'createRemote': {
							'title': i18n.t('menu.createRemote'),
							'action': ()=>this.createNewGameAtServer(),
						},
						'joinRemote': {
							'title': i18n.t('menu.joinRemote'),
							'action': ()=>{
								const gameId = window.prompt(i18n.t('menu.joinRemotePrompt'), '');
								if (!gameId || gameId===''){
									console.log('No game id given, cancel join');
									return;
								}
								this.joinGame(gameId);
							},
						},
						'divider2':'divider',
						'switchLangDe': {
							'title': i18n.t('menu.lang.de'),
							'action': ()=>this.changeLanguage('de'),
						},
						'switchLangEn': {
							'title': i18n.t('menu.lang.en'),
							'action': ()=>this.changeLanguage('en'),
						},
					}}/>
					<div className={`statusBlock ${statusTurnClass}`}>
						<p className="status">{status}</p>
					</div>
				</div>
			<Notification 
				winnerIs={i18n.t('end.notification', {
					player: this.state.winnerIs==='rhino' ? i18n.t('game.rhino') : i18n.t('game.elephant'), 
					playerChar: this.state.winnerIs==='rhino' ? this.rhinoChar : this.elephantChar
				})}
				show={this.state.winnerIs!==undefined && this.state.winnerIs!==null && this.state.winnerIs!=='' ? 'show' : 'hide'}
				onClose={()=>this.resetGame()}
				closeText={i18n.t('end.closeNotification')}
			/>
			<div className={`focusOverlay ${focusCell}`} onClick={()=>this.deselect()}></div>
			<div className="userActionPanel">
				<span className={`animalsLeft actionButtons`}>
					<span className="leftCounter">{elephantsLeft}</span>
					<span className="elephant" role="img" aria-label="Elephants left for inserting into the field">&#x1f418;</span>
				</span>
				<span className={`animalsLeft actionButtons`}>
					<span className="leftCounter">{rhinosLeft}</span>
					<span className="rhino" role="img" aria-label="Rhinos left for inserting into the field">&#x1F98F;</span>
				</span>
				
				<button onClick={()=>this.switchPlayer(this.state.squares, this.state.rotation)} 
					className="actionButtons"
					data-action="switch"
					title="Hand over turn to your opponent">
					<span role="img" aria-label="End your current turn and switch to other user">&#x21C4;</span>
				</button>
			</div>

			<ActionWizzard
				className={this.state.selectedCell!==-1 && this.state.wizzard==='main'? 'show' : 'hide'}
				actions={actions}
				actionStyle={actionStyle}
				rhinoIsNext={this.state.rhinoIsNext}
				total={this.state.totalAnimals}
				rhinosLeft={rhinosLeft}
				elephantsLeft={elephantsLeft}
				insertAction={(rotation)=>this.insertNewAnimal(rotation)}
				moveAction={(direction)=>this.move(direction)}
				removeAction={()=>this.remove()}
				deselectAction={()=>this.deselect()}
				rotateAction={()=>this.enableRotationWizard()}
			/>
			<ActionRotationWizzard 
				className={this.state.selectedCell!==-1 && this.state.wizzard==='rotate' ? 'show' : 'hide'}
				rotateAction={(direction)=>this.setRotation(direction)}
				actionStyle={actionStyle}
			/>
			<ActionInsertPushWizzard 
				className={this.state.selectedCell!==-1 && this.state.wizzard==='insertPush' ? 'show' : 'hide'}
				insertPushAction={(direction)=>this.insertPush(this.state.selectedCell, direction)}
				actions={actions}
				actionStyle={actionStyle}
			/>

			<div className="game-board">
				<Board 
					squares={this.state.squares}
					rotation={this.state.rotation}
					selected={this.state.selectedCell}
					onClick={(i) => this.handleClick(i)}
					onClickBorder={(i, rotation) => this.insertPush(i, rotation)}
					referenceUpdate={(el) => this.referenceUpdate(el)}
				/>
			</div> 
			<MessageBox 
				showWarning={showWarningMessage}
				currentWarning={this.state.currentWarning}
				clearMessageCallback={()=>this.clearWarningMessage()}
			/>
			{this.state.remoteGameId && 
			<div>
				Game id: <strong>{this.state.remoteGameId}</strong><br/>
				I am: <strong className={this.state.remoteIAm}>{this.state.remoteIAm}</strong><br/>
				{this.state.remoteIAm!=='elephant' && 
					<div>
					Link to share with opponent: <br/>
					<input type="text" title="share this link with someone to play with you" className="historyButtons"
						value={'http://game.thilobeckmann.de/?game='+this.state.remoteGameId}/><br/>
					</div>
				}
				<button onClick={() => this.loadRemoteGameState()} className="historyButtons">force reload from remote</button>
			</div>
			}
			</div>
			{!this.state.remoteGameId &&
			<div className="game-info">
				{moves}
			</div>
			}
		</div>
		);
	}

	updateUrlWithRemoteGameInformation(state, gameId, player, sessionId){
		if (gameId){
			window.history.pushState(state, i18n.t('game.remote.title'), '?game='+gameId+'&player='+player+'&session='+sessionId);
		}
		else {
			window.history.pushState(state, i18n.t('game.title'), '?');
		}
	}

	createNewGameAtServer(){
		fetch('https://game.thilobeckmann.de/game-state/db-handler.php?create=game')
		.then(response => {console.log(response); return response.json();})
		.then(data => {
			console.log(data);
			const state = { 
				remoteGameId: data.game,
				remoteSessionId: data.session,
				remoteIAm: 'rhino',
			}; 
			this.setState(state);
			this.updateUrlWithRemoteGameInformation(state, data.game, 'rhino', data.session);
		})
		.then(()=>this.loadRemoteGameState())
		.then(()=>{
			const interval = window.setInterval(()=>this.loadRemoteGameState(), 5000);
			this.setState({
				remoteReloadTimer: interval,
			});
		})
		.catch(error => alert('Failed to create remote game: ' + error.message));
	}

	loadRemoteGameState(){
		const self = this;
		const url = 'https://game.thilobeckmann.de/game-state/db-handler.php?load='+this.state.remoteGameId;
		console.log(url);
		fetch(url)
		.then(response => {
			console.log('response from server: ', response); 
			return response.json();
		})
		.then(data => {
			console.log('json response from server: ', data); 
			self.applyRemoteLoadedState(data);
			self.setState({
				deselectAllowed: true,
				selectAllowed: true,
			})
		})
		.catch(error => alert('Failed to load remote game state:' + error.message));
	}

	applyRemoteLoadedState(data){
		console.log('data: ', data);
		const converted = this.convertFrom(data.squares, data.rotation).slice();
		if (!converted){
			throw new Error('No game state arrived!');
		}
		if ((this.state.remoteIAm==='rhino' && data.rhinoIsNext) 
			|| (this.state.remoteIAm==='elephant' && !data.rhinoIsNext)){
			window.clearInterval(this.state.remoteReloadTimer);
		}
		console.log('Converted: ', converted);
		this.setState({
			squares: converted[0].squares.slice(),
			rotation: converted[0].rotation.slice(),
			winnerIs: data.endState,
			rhinoIsNext: data.rhinoIsNext,
		});
	}

	saveRemote(squares, rotation, currentSession, winnerIs){
		const minifiedSquares = this.getMinifiedSquares(squares);
		const minifiedRotation = this.getMinifiedRotation(rotation);

		fetch('https://game.thilobeckmann.de/game-state/db-handler.php?save='+this.state.remoteGameId, {
			method: 'post',
			body: JSON.stringify({
				squares: minifiedSquares,
				rotation: minifiedRotation,
				me: currentSession,
				state: winnerIs,
			}),
		})
		.catch(error => alert(error.message));

		/*.then(function(response) {
			console.log(response);
			return response.json();
		})
		.then(function(data) {
			console.log('saved:', data);
		});*/
	}

	saveRemoteReset(currentSession, startPlayer){
		fetch('https://game.thilobeckmann.de/game-state/db-handler.php?reset='+this.state.remoteGameId, {
			method: 'post',
			body: JSON.stringify({
				me: currentSession,
				startPlayer: startPlayer,
			}),
		})
		.catch(error => alert(error.message));

		/*.then(function(response) {
			console.log(response);
			return response.json();
		})
		.then(function(data) {
			console.log('saved:', data);
		});*/
	}


	getMinifiedSquares(squares) {
		let field = '';
		for (let i=0; i<25; i++){
			const cell = squares[i];
			if (cell === 'elephant'){
				field = field + 'e';
			}
			else if (cell === 'rhino'){
				field = field + 'r';
			}
			else if (cell === 'mountain'){
				field = field + 'm';
			}
			else {
				field = field + '_';
			}
			if ((i+1)%5===0 && i!==0){
				field = field + ' ';
			}
		}
		return field;
	}
	getMinifiedRotation(rotation) {
		let field = '';
		for (let i=0; i<25; i++){
			const cell = rotation[i];
			if (cell === 'north'){
				field = field + '^';
			}
			else if (cell === 'east'){
				field = field + '>';
			}
			else if (cell === 'west'){
				field = field + '<';
			}
			else if (cell === 'south'){
				field = field + 'v';
			}
			else {
				throw new Error('Unknown rotation: '+cell);
			}
			if ((i+1)%5===0 && i!==0){
				field = field + ' ';
			}
		}
		return field;
	}

	joinGame(gameId){
		console.log('join game: ', gameId);
		this.resetGame();
		fetch('https://game.thilobeckmann.de/game-state/db-handler.php?join='+gameId)
		.then(response => response.json())
		.then(data => {
			console.log('join game response', data); 
			const state = {
				remoteGameId: data.game,
				remoteSessionId: data.session,
				remoteIAm: 'elephant',
			};
			this.setState(state); 
			this.updateUrlWithRemoteGameInformation(state, data.game, 'elephant', data.session);
		})
		.then(()=>this.loadRemoteGameState())
		.then(()=>{
			const interval = window.setInterval(()=>this.loadRemoteGameState(), 5000);
			this.setState({
				remoteReloadTimer: interval,
			});
		})
		.catch(error => alert('Failed to join remote game: ' + error.message));
	}

	enableRotationWizard(){
		if (this.state.selectedCell===-1){
			this.setWarningMessage(i18n.t('warnings.rotationWizzard.notSelected'));
			return;
		}
		if (this.state.squares[this.state.selectedCell]!==this.getCurrentPlayer()){
			this.setWarningMessage(i18n.t('warnings.rotationWizzard.notOwnPlayer'));
			return;
		}
		this.setState({
			wizzard: 'rotate',
		});
	}
	enableInsertPushWizard(){
		if (this.state.selectedCell===-1){
			this.setWarningMessage(i18n.t('warnings.insertPushWizzard.notSelected'));
			return;
		}
		this.setState({
			wizzard: 'insertPush',
		});
	}

	isCellOccupied(i){
		const squares = this.state.squares;
		return squares[i]==='rhino' || squares[i]==='elephant' || squares[i]==='mountain';
	}
	
	deselect(){
		if (this.state.deselectAllowed){
			this.setState({
				selectedCell: -1,
				wizzard: null,
			});
		}
		this.clearWarningMessage();
	}

	insertPush(i, insertRotation){

		if (!insertRotation){
			throw new Error("no rotation given. FIXME. error");
		}
		if (i===-1 || i===undefined || i===null){
			this.setWarningMessage(i18n.t('warnings.insertPush.notSelected'));
			return;
		}
		if(!this.isBorderCell(i)){
			this.setWarningMessage(i18n.t('warnings.insertPush.noBorder'));
			return false;
		}
		
		const squares = this.state.squares.slice();
		const rotation = this.state.rotation.slice();
		const currentPlayer = this.getCurrentPlayer();
		
		const numberOfAnimalsInGame = squares.filter(item => item===currentPlayer).length;

		if (numberOfAnimalsInGame >= this.state.totalAnimals){
			this.setWarningMessage(i18n.t('warnings.insertPush.limitReached', {total: this.state.totalAnimals, player: currentPlayer }));
			return;
		}

		if (!this.isEmpty(squares[i]))
		{
			let result = this.moveStepIntoDirection(insertRotation, squares, rotation, i, true);
			
			if (result === undefined || result === null){
				this.setWarningMessage(i18n.t('warnings.insertPush.tooMuchToPush'));
				return;
			}
			
			const newSquares = result.squares;
			const newRotation = result.rotation;
			
			newSquares[i] = currentPlayer;
			newRotation[i] = insertRotation;
			
			this.switchPlayer(newSquares, newRotation, result.winnerIs);
			
			console.log(result);
			return; 
		}
	
		squares[i] = currentPlayer;
		rotation[i] = insertRotation;
		console.log('Inserted ' + currentPlayer + " at " + i);
		
		this.setState({
			insertDone: true,

			removeAllowed: false,
			pushAllowed: false,
			moveAllowed: false,
			insertAllowed: false,
			rotateAllowed: true,
			selectAllowed: false,
			deselectAllowed: false,

			selectedCell: i,
		});
		
		console.log('real history: ' + this.state.history.length);
		this.switchPlayer(squares, rotation);
	}

	/**
	 * Clear history to the given step and load this into the board. 
	 * @param {number} step the number to load
	 */
	jumpTo(step){
		const newHistory = JSON.parse(JSON.stringify(this.state.history.slice(0, step+1)));

		this.setState({
			rhinoIsNext: (step % 2) === 0,
			selectedHistory: step,
			clearHistoryAction: (newSquares, newRotation) => {
				return newHistory.concat([{
					squares: newSquares,
					rotation: newRotation,
				}]);
			},
			//history: newHistory,

			squares: newHistory[step].squares,
			rotation: newHistory[step].rotation,

			selectedCell: -1,
			deselectAllowed: true,
			selectAllowed: true,
			moveDone: false,
			pushDone: false,
			insertDone: false,
			rotateDone: false,
			selectDone: false,
		});
	}

	getCurrentPlayer(){
		const currentPlayer = this.state.rhinoIsNext?'rhino':'elephant';
		return currentPlayer;
	}
	
	setWarningMessage(message){
		console.log(message);
		this.setState({
			currentWarning: message,
		});
	}
	clearWarningMessage(){
		this.setState({
			currentWarning: undefined,
		});
	}
  
	/**
	 * handle cell click selection.
	 * @param {number} i the cell number that has been clicked on (depending on the current turn history, there might get new actions enabled)
	 */
	handleClick(i){
		this.clearWarningMessage();
		if (this.state.selectedCell===i){
			this.setState({
				selectedCell: -1,
			});
			return;
		}
		
		const currentPlayer = this.getCurrentPlayer();
		const squares = this.state.squares.slice();
		const rotation = this.state.rotation.slice();
		
		const actions = this.getAvailableActions(i, squares, rotation, currentPlayer);

		if (!this.state.deselectAllowed && !this.state.selectAllowed){
			console.log('Deselect / select another field is not allowed at this moment');
			this.setWarningMessage(i18n.t('warnings.select.deselectProhibited'));
			return;
		}
		if (this.state.winnerIs){
			console.log('The game is over. Winner is: ' + this.state.winnerIs + ' Start a new game, if you like to play again.');
			return;
		}
		
		if (actions.length===0){
			this.setWarningMessage(i18n.t('warnings.select.nothingCanDo'));
		}
		
		this.setState({
			selectedCell: i,
			wizzard: 'main',

			selectAllowed: true, //nothing happened yet, allow other selection
			deselectAllowed: true, //nothing happened yet, allow deselection current selection
			insertAllowed: actions.includes('insert') || actions.includes('insertPush'),
			pushAllowed: actions.includes('push'),
			moveAllowed: actions.includes('move'),
			removeAllowed: actions.includes('remove'),
			rotateAllowed: actions.includes('rotate'),
		});
		console.log('selected cell ' + i + '. turn continues');
		return;
	}

	/**
	 * Callback of the square that is selected with its "ref" attribute. This allows to get the position of the current selected square to locate the button overlay for available actions.
	 * @param {*} selectedSquare the elements information of react.
	 */
	referenceUpdate(selectedSquare){
		if (!selectedSquare){
			return;
		}
		
		if (this.state.selectedCellBoundary 
			&& this.state.selectedCellBoundary.top === selectedSquare.offsetTop
			&& this.state.selectedCellBoundary.left === selectedSquare.offsetLeft
			&& this.state.selectedCellBoundary.width === selectedSquare.offsetWidth
			&& this.state.selectedCellBoundary.height === selectedSquare.offsetHeight){
			console.log('no update to selected cell boundary');
			return;
		}
		else{
			console.log('top:'+selectedSquare.offsetTop + ' w:'+selectedSquare.offsetWidth + ' left:'+selectedSquare.offsetLeft + ' height:'+selectedSquare.offsetHeight);
		}
		
		this.setState({
			selectedCellBoundary: {
				top: selectedSquare.offsetTop,
				width: selectedSquare.offsetWidth,
				left: selectedSquare.offsetLeft,
				height: selectedSquare.offsetHeight,
			},
		});
	}

	getAvailableActions(i, squares, rotation, currentPlayer){
		let actions = [];
		const isCurrentPlayer = squares[i]===currentPlayer;
		const isBorder = this.isBorderCell(i);
		const numberOfAnimals = squares.filter(item => item===currentPlayer).length;
		const maxAnimals = this.state.totalAnimals;
		const currentRotation = rotation[i];
		if (isCurrentPlayer && isBorder){
			actions.push('remove');
		}
		if (isBorder && numberOfAnimals < maxAnimals){
			if (this.isEmpty(squares[i])){
				actions.push('insert');
			}
			else {
				actions.push('insertPush'); //TODO: check if it's too much load
				if (i>=0 && i<=4){
					actions.push('insertPushSouth');
				}
				if (i===0 || i===5 || i===10 || i===15 || i===20){
					actions.push('insertPushEast');
				}
				if (i===4 || i===9 || i===14 || i===19 || i===24){
					actions.push('insertPushWest');
				}
				if (i>=20 && i<=24){
					actions.push('insertPushNorth');
				}
			}
		}
		if (isCurrentPlayer){
			if (this.isEmpty(squares[this.northOf(i)])){
				actions.push('moveNorth');
				actions.push('rotateAfterMove');
				actions.push('move');
			}
			if (this.isEmpty(squares[this.southOf(i)])){
				actions.push('moveSouth');
				actions.push('rotateAfterMove');
				actions.push('move');
			}
			if (this.isEmpty(squares[this.westOf(i)])){
				actions.push('moveWest');
				actions.push('rotateAfterMove');
				actions.push('move');
			}
			if (this.isEmpty(squares[this.eastOf(i)])){
				actions.push('moveEast');
				actions.push('rotateAfterMove');
				actions.push('move');
			}
			actions.push('rotate');
			if (currentRotation==='north' && !this.isEmpty(squares[this.northOf(i)])){
				actions.push('push');//TODO: check is possible / too much load
				actions.push('pushNorth');
			}
			if (currentRotation==='south' && !this.isEmpty(squares[this.southOf(i)])){
				actions.push('push');//TODO: check is possible / too much load
				actions.push('pushSouth');
			}
			if (currentRotation==='west' && !this.isEmpty(squares[this.westOf(i)])){
				actions.push('push');//TODO: check is possible / too much load
				actions.push('pushWest');
			}
			if (currentRotation==='east' && !this.isEmpty(squares[this.eastOf(i)])){
				actions.push('push');//TODO: check is possible / too much load
				actions.push('pushEast');
			}
		}
		console.log(actions);
		return actions;
	}

	isEmpty(cellValue){
		return cellValue===undefined || cellValue===null || cellValue==='empty';
	}
	getCellOf(direction, i){
		if (direction==='north') return this.northOf(i);
		if (direction==='east') return this.eastOf(i);
		if (direction==='south') return this.southOf(i);
		if (direction==='west') return this.westOf(i);
		throw new Error('unhandled direction '+direction);
	}

	northOf(i){
		if (i-5<0){
			return -1;
		}
		return i-5;
	}
	southOf(i){
		if (i+5>24){
			return -1;
		}
		return i+5;
	}
	westOf(i){
		if (i%5===0){
			return -1;
		}
		return i-1;
	}
	eastOf(i){
		if (i%5===4){
			return -1;
		}
		return i+1;
	}

	/**
	 * Checks whether the given cell number is a border cell
	 * @param {number} i the cell number
	 */
	isBorderCell(i){
		return i<=5 || i===9 || i===10 || i===14 || i===15 || i===19 || i>=20;
	}

	insertNewAnimal(rotation){
		this.insertNewAnimalAt(this.state.selectedCell, rotation);
	}

	/**
	 * Insert the current player at the given cell number
	 * @param {number} i the cell number
	 */
	insertNewAnimalAt(i, newRotation){
		if (i===-1 || i===undefined || i===null){
			this.setWarningMessage(i18n.t('warnings.insert.notSelected'));
			return;
		}
		
		const squares = this.state.squares.slice();
		const rotation = this.state.rotation.slice();
		const currentPlayer = this.getCurrentPlayer();
		
		if(!this.isBorderCell(i)){
			this.setWarningMessage(i18n.t('warnings.insert.noBorder'));
			return false;
		}
		
		const numberOfAnimalsInGame = squares.filter(item => item===currentPlayer).length;

		if (numberOfAnimalsInGame >= this.state.totalAnimals){
			this.setWarningMessage(i18n.t('warnings.insert.limitReached', {total: this.state.totalAnimals, player: currentPlayer }));
			return;
		}
		
		if (!this.isEmpty(squares[i])){
			
			if (!newRotation && (i===0 || i===4 || i===20 || i===24)){
				this.enableInsertPushWizard();
				return;
			}
			
			this.insertPush(i, this.getDefaultRotationForCell(i));
			return;
		}

		squares[i] = currentPlayer;
		rotation[i] = this.getDefaultRotationForCell(i);

		console.log('Inserted ' + currentPlayer + ' at ' + i + ' with ' + rotation[i]);
		
		this.setState({
			insertDone: true,

			removeAllowed: false,
			pushAllowed: false,
			moveAllowed: false,
			insertAllowed: false,
			rotateAllowed: true,
			selectAllowed: false,
			deselectAllowed: false,
			wizzard: 'rotate',

			selectedCell: i,
		});
		
		this.updateBoard(squares, rotation);
	}
	moveStepIntoDirection(direction, squares, rotation, i, startAtSeletedCell){
		let result;
		if (direction==='south'){
			result = this.moveStep(squares, rotation, i, 
				((j)=>j+5), ((a,b)=>a>=b), ((j)=>j-5), (j)=>j<25,
				startAtSeletedCell ? 'south' : undefined);
		}
		else if (direction==='north'){
			result = this.moveStep(squares, rotation, i, 
				((j)=>j-5), ((a,b)=>a<=b), ((j)=>j+5), (j)=>j>=0,
				startAtSeletedCell ? 'north' : undefined);
		}
		else if (direction==='east'){
			result = this.moveStep(squares, rotation, i, 
				((j)=>j+1), ((a,b)=>a>=b), ((j)=>j-1), (j)=>j<=Math.floor(i/5)*5 + 4, 
				startAtSeletedCell ? 'east' : undefined);
		}
		else if (direction==='west'){
			result = this.moveStep(squares, rotation, i, 
				((j)=>j-1), ((a,b)=>a<=b), ((j)=>j+1), (j)=>j>=Math.floor(i/5)*5, 
				startAtSeletedCell ? 'west' : undefined);
		}
		return result;
	}
	
	/**
	 * @param {number} i the cell to get the default rotation for inserts
	 * @returns {String} the rotation
	 */
	getDefaultRotationForCell(i){
		if (i>=0 && i<=4){
			return 'south';
		}
		else if (i>=20 && i<=24){
			return 'north';
		}
		else if (i===5 || i===10 || i===15){
			return 'east';
		}
		else if (i===9 || i===14 || i===19){
			return 'west';
		}
		return 'north';
	}


	/**
	 * Move the selected cell i (or a fictive cell outside the board next to i with fictiveStartRotation) 
	 * and move into the direction of the selected cell / fictiveStartRotation
	 * 
	 * @param {String[]} squares the cell types from 0 to 14 containing 'empty', 'mountain', 'rhino' or 'elephant'
	 * @param {String[]} rotation the cell's rotation. 'north', 'south', 'east' or 'west'
	 * @param {number} i the current selected cell (some special handling, if moving from outside the board cf. fictiveStartRotation)
	 * @param {*} cellMoveIncrement the method to increment one step into the rotation of the selected cell (or the fictiveStartRotation if available)
	 * @param {*} loopCheck the method to check when stop to check for push elements
	 * @param {*} cellDecrement the inverse method of 'cellMoveIncrement'
	 * @param {*} boundaryCheck the end of the row compared to the selected rotation / fictiveStartRotation
	 * @param {String} fictiveStartRotation if pushing from outside there is no real selection and no selected rotation. use this rotation instead. Leave undefined if cell is selected.
	 */
	moveStep(squares, rotation, i, cellMoveIncrement, loopCheck, cellDecrement, boundaryCheck, fictiveStartRotation){
		let moveCompleted = false;
		let counterForces = 0;
		let finalCell = null;
		let j = cellMoveIncrement(i);
		if (fictiveStartRotation){
			j = i;
		}
		let pushedSomething = false;
		while (boundaryCheck(j) && !moveCompleted){
			finalCell = j;
			if (this.isEmpty(squares[j])){
				moveCompleted = true;
				break;
			}
			else if (squares[j]==='mountain'){
				counterForces = counterForces + 0.9;
				console.log('you have to push a mountain at '+j + ' forces:'+counterForces);
			}
			else {
				const theOtherRotation = rotation[j];
				const myRotation = fictiveStartRotation ? fictiveStartRotation : rotation[i];
				const myOppositeDirection = this.getOppositeOf(myRotation);
				if (theOtherRotation === myRotation){
					counterForces = counterForces - 1;
					console.log('You get support by ' + squares[j] + ' at ' + j + ' forces:'+counterForces);
				}
				else if (theOtherRotation === myOppositeDirection){
					console.log('You get counter force by ' + squares[j] + ' at ' + j + ' forces:'+counterForces);
					counterForces = counterForces + 1;
				}
				else {
					//This is "not mountain", "not empty" => any animal
					//This is not same rotation and not opposite direction => orthogonal direction (which is indifferent)
					console.log('indifferent animal; at ' + j + ' forces:'+counterForces);
				}
			}
			if (counterForces>=1){
				this.setWarningMessage(i18n.t('warnings.move.tooMuchToPush'));
				return;
			}
			pushedSomething = true;
			j = cellMoveIncrement(j);
		}
		let winnerDetected = null;
		if (finalCell!==undefined && finalCell!==null){
			//check if the game has ended (= mountain drops of the board)
			//winner is the animal next to the mountain looking into the push direction
			if (squares[finalCell]==='mountain'){
				console.log('A mountain moved from the board! The game ends');
				for (let j=finalCell; loopCheck(j,i); j=cellDecrement(j)){
					console.log('check for winner cell ' + j + ' type: '+squares[j]+' with rotation '+rotation[j]); 
					if (squares[j]!=='mountain' && rotation[j]===rotation[i]){
						console.log('And the winner is: ' + squares[j]);
						winnerDetected = squares[j];
						document.title='Winner is ' + squares[j];
						break;
					}
				}
			}
			for (let j=finalCell; loopCheck(j,i); j=cellDecrement(j)){
				console.log('move cell ' + (cellDecrement(j)) + '(type: '+squares[cellDecrement(j)]+')   to ' + j + '(type: '+squares[j]+') (limit: '+i+')'); 
				squares[j] = squares[cellDecrement(j)];
				rotation[j] = rotation[cellDecrement(j)];
			}
		}
		squares[i]='empty';
		rotation[i]='north';
		
		return {
			squares: squares,
			rotation: rotation,
			pushedSomething: pushedSomething,
			winnerIs: winnerDetected,
		};
	}

	/**
	 * Move selected animal into the given direction. (push only possible if direction is equal to current orientation)
	 * @param {'north','east','south','west'} direction the direction to move the currently selected animal to
	 */
	move(direction) {
		const i = this.state.selectedCell;
		if (i===-1){
			this.setWarningMessage(i18n.t('warnings.move.notSelected'));
			return;
		}
		/*
		if (this.state.pushDone || this.state.moveDone){
			if(this.state.rotateDone){
				this.setWarningMessage('You already did a move and rotated in your turn, just switch turns');
			}
			else{
				this.setWarningMessage('You already did a move in your turn, do a rotation change or just switch turns');
			}
			return;
		}
		if (!this.state.pushAllowed && !this.state.moveAllowed){
			this.setWarningMessage('Move not enabled at the moment');
			return;
		}*/
		const currentPlayer = this.getCurrentPlayer();
		
		let squares = this.state.squares.slice();
		let rotation = this.state.rotation.slice();
		let selectedDirection = rotation[i];
		
		if (squares[i]!==currentPlayer){
			this.setWarningMessage(i18n.t('warnings.move.notOwnPlayer'));
			return;
		}

		let result = null;
		if(direction===undefined){
			result = this.moveStepIntoDirection(rotation[i], squares, rotation, i, false);
		}
		else if (this.isEmpty(squares[this.getCellOf(direction, i)])){
			result = this.moveStepIntoDirection(direction, squares, rotation, i, false);
			selectedDirection = direction;
		}
		else if (direction===rotation[i]){
			result = this.moveStepIntoDirection(rotation[i], squares, rotation, i, false);
		}
		else{
			this.setWarningMessage(i18n.t('warnings.move.targetNotEmpty', {direction: direction}));
			return;
		}
		if (result === undefined || result === null){
			return;
		}
		/*
		if (result.pushedSomething && !this.state.pushAllowed){
			this.setWarningMessage('You may only move, but not push any other obstacle.');
			return;
		}*/
		this.moveSelection(selectedDirection);
		squares = result.squares;
		rotation = result.rotation;
		
		
		if (result.pushedSomething){
			this.switchPlayer(squares, rotation, result.winnerIs);
			return;
		}
		//this.updateBoard(squares, rotation);
		
		this.setState({
			squares: squares, 
			rotation: rotation,
			winnerIs: result.winnerIs,
			moveDone: true,
			pushDone: result.pushedSomething,
			wizzard: 'rotate',
			insertAllowed: false,
			pushAllowed: false,
			moveAllowed: false,
			removeAllowed: false,
			rotateAllowed: true,
			selectAllowed: false,
			deselectAllowed: false,
		});
	}
	moveSelection(direction){
		let selected = this.state.selectedCell;
		
		if (direction==='north'){
			selected = selected - 5;
			selected = selected < 0 ? -1 : selected;
		}
		else if (direction==='south'){
			selected = selected + 5;
			selected = selected > 24 ? -1 : selected;
		}
		else if (direction==='east'){
			const cellThatWillBeOffBoard = Math.floor(selected/5)*5 + 4;
			selected = (selected === cellThatWillBeOffBoard) ? -1 : selected + 1;
		}
		else if (direction==='west'){
			selected = (selected%5===0) ? -1 : selected - 1;
		}
		console.log('selection: ' + this.state.selectedCell + '  direction: ' + direction + '  new: ' + selected);
		if (selected===-1){
			//DO nothing, switch player will be done in calling code
			//this.switchPlayer();
		}
		else{
			this.setState({
				selectedCell: selected,
			});
		}
	}
	
	getOppositeOf(direction){
		if (direction==='north') return 'south';
		if (direction==='east') return 'west';
		if (direction==='south') return 'north';
		if (direction==='west') return 'east';
		
		return 'invalid input: '+direction;
	}
	
	updateBoard(newSquares, newRotation){
		this.setState({
			squares: newSquares,
			rotation: newRotation,
		});
	}

	remove () {
		const i = this.state.selectedCell;
		if (i===-1){
			this.setWarningMessage(i18n.t('warnings.remove.notSelected'));
			return;
		}
		if (!this.isBorderCell(i)){
			this.setWarningMessage(i18n.t('warnings.remove.noBorder'));
			return;
		}
		
		const currentPlayer = this.getCurrentPlayer();
		
		const squares = this.state.squares.slice();
		const rotation = this.state.rotation.slice();
		
		if (squares[i]!==currentPlayer){
			this.setWarningMessage(i18n.t('warnings.remove.notOwnPlayer'));
			return;
		}
		squares[i] = 'empty';
		rotation[i] = 'north';
		this.switchPlayer(squares, rotation);
	}
	
	resetRemoteWrongTryToTurn(){
		this.setState({
			wizzard: null,
			selectedCell: -1,
		});
	}

	/**
	 * set the other player as current player. 
	 * reset all done actions, to allow the next player do his/her turn.
	 */
	switchPlayer (squares, rotation, winnerIs) {
		if (this.state.remoteGameId){
			if (this.state.remoteIAm==='rhino' && !this.state.rhinoIsNext){
				this.setWarningMessage(i18n.t('game.remote.notYourTurn'));
				this.resetRemoteWrongTryToTurn();
				return;
			}
			else if (this.state.remoteIAm==='elephant' && this.state.rhinoIsNext){
				this.setWarningMessage(i18n.t('game.remote.notYourTurn'));
				this.resetRemoteWrongTryToTurn();
				return;
			}
			else{
				console.log('Remote game. player '+this.state.remoteIAm + ' makes the turn');
			}
		}
		const detachedSquares = JSON.parse(JSON.stringify(squares));
		const detachedRotation = JSON.parse(JSON.stringify(rotation))

		let newHistory = null; 
		if (this.state.clearHistoryAction){
			newHistory = this.state.clearHistoryAction(detachedSquares, detachedRotation);
		}
		else{
			newHistory = this.state.history.concat([{
				squares: detachedSquares,
				rotation: detachedRotation,
			}])
		}

		this.setState({
			currentWarning: undefined,

			rhinoIsNext: !this.state.rhinoIsNext,
			selectAllowed: true,
			rotateAllowed: false,
			wizzard: null,
			moveAllowed: false,
			insertAllowed: false,
			removeAllowed: false,

			selectDone: false,
			insertDone: false,
			removeDone: false,
			rotateDone: false,
			moveDone: false,
			pushDone: false,

			selectedCell: -1,
			selectedHistory: newHistory.length-1,

			squares: detachedSquares, 
			rotation: detachedRotation,

			clearHistoryAction: null,
			history: newHistory,
			winnerIs: winnerIs,
		});
		if (this.state.remoteGameId){
			this.saveRemote(detachedSquares, detachedRotation, this.state.remoteSessionId, winnerIs);
			const interval = window.setInterval(()=>this.loadRemoteGameState(), 5000);
			this.setState({
				remoteReloadTimer: interval,
			});
		}
		
	}
  
	setRotation (newRotation){
		if (this.state.selectedCell===-1){
			this.setWarningMessage(i18n.t('warnings.rotate.notSelected'));
			return;
		}
		const i = this.state.selectedCell;
		if (this.state.rotateAllowed){
			const squares = this.state.squares.slice();
			const rotation = this.state.rotation.slice();
			
			console.log('rotate selected ' + i + ' with ' + squares[i] + ' from ' + rotation[i] + ' to ' + newRotation);
			
			rotation[i] = newRotation;
			
			this.switchPlayer(squares, rotation);
		}
	}

	resetGame(){
		this.clearWarningMessage();
		if (this.state.remoteGameId){
			this.resetRemoteGame();
		}
		else {
			this.resetLocalGame();
		}
	}

	resetRemoteGame(){
		const initialBoard = this.convertFrom(
			'_____ _____ _mmm_ _____ _____', 
			'^^^^^ ^^^^^ ^^^^^ ^^^^^ ^^^^^'
		);
		const rhinoStartNext = this.getOpponent(this.state.winnerIs)==='rhino';
		this.setState({
			history: initialBoard,

			squares: initialBoard[0].squares.slice(),
			rotation: initialBoard[0].rotation.slice(),

			totalAnimals: 5,
			//the different turn options a player can do 
			//(some can be combined with others)
			selectDone: false,
			insertDone: false,
			pushDone: false,
			rotateDone: false,
			removeDone: false,
			
			selectAllowed: true,
			deselectAllowed: false,
			rotateAllowed: false,
			pushAllowed: false,
			moveAllowed: false,
			insertAllowed: false,
			removeAllowed: false,

			winnerIs: null,
			selectedCell: -1,
			selectedHistory: 0,
			
			clearHistoryAction: null,
			//make the looser start the next game
			rhinoIsNext: this.getOpponent(this.state.winnerIs)==='rhino',
		}); 

		this.saveRemoteReset(this.state.remoteSessionId, this.getOpponent(this.state.winnerIs));
		this.loadRemoteGameState();
	}

	getOpponent(player){
		return player === 'rhino' ? 'elephant' : 'rhino';
	}

	resetLocalGame(){
		const initialBoard = this.convertFrom(
			'_____ _____ _mmm_ _____ _____', 
			'^^^^^ ^^^^^ ^^^^^ ^^^^^ ^^^^^'
		);
		this.setState({
			history: initialBoard,

			squares: initialBoard[0].squares.slice(),
			rotation: initialBoard[0].rotation.slice(),

			totalAnimals: 5,
			//the different turn options a player can do 
			//(some can be combined with others)
			selectDone: false,
			insertDone: false,
			pushDone: false,
			rotateDone: false,
			removeDone: false,
			
			selectAllowed: true,
			deselectAllowed: false,
			rotateAllowed: false,
			pushAllowed: false,
			moveAllowed: false,
			insertAllowed: false,
			removeAllowed: false,

			winnerIs: null,
			selectedCell: -1,
			selectedHistory: 0,
			
			clearHistoryAction: null,
			remoteGameId: null,
			remoteSessionId: null,
			rhinoIsNext: true,
		});
	}
}
export default withTranslation('translations')(Game);