import React from 'react';
import ReactDOM from 'react-dom';
import Game from './game.js';
import {I18nextProvider} from 'react-i18next';
import i18n from './i18n';
/*
In Siam, rhinoceros and elephants are competing to be the first to push a rock out of the board. 
An elephant or a rhinoceros can push a rock, but you need two animals pushing in the same direction to push two aligned rocks. 
An animal can push another animal too, except if the other animal is pushing in the opposite direction. 
And finally, an animal can push a whole line with multiple rocks and animals, if opposite forces don't cancel his effort. 

So you will have to be very mindful of the direction in which every animal is pushing, which is indicated by an arrow above the animal.

On your turn, you can do one of the following actions:

    place one of your animals on the board (on one of the border cells)
    remove one of your animals from the board (if it is on one of the border cells)
    move one of your animals, then choose the direction it is facing
    stay in place and change the direction one of your animals is facing without moving it
    or push! Either with an animal already on the board, or with an animal outside of the board, pushing while entering the board. 

This simple set of rules creates a lot of possibilities. You'll be really surprised to see how often a situation seems hopeless, and still, one push done the right way will change everything! 
*/


// ========================================

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const gameId = urlParams.get('game');
const session = urlParams.get('session');
const player = urlParams.get('player');

ReactDOM.render(
	<I18nextProvider i18n={ i18n }>
		<Game 
			initFields=   "_e___ _emre __m__ r_m__ __rem"
			initRotations="^>^^^ ^>^<< ^^^^^ v^^^^ ^^>>^"
			remoteGameId={gameId}
			remoteSessionId={session}
			remoteIAm={player}
		/>
	</I18nextProvider>,
	document.getElementById('root')
);
