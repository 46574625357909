import React from 'react';
import arrow from './arrow.svg';
import arrowWhite from './arrowWhite.svg';

export default class Square extends React.Component{
	getGraphicForType(type){
		if (type==='rhino'){
			return <img src={arrow} width="50" height="50" alt="rhino"/>
			//return <>&Delta;</>;
			//return <>&#129153;</>;
			//return <>&#129423;</>;
		}
		else if (type==='elephant'){
			return <img src={arrowWhite} width="50" height="50" alt="elephant"/>
			//return <>&#129093;</>;
			//return <>&#128024;</>;
		}
		else if (type==='mountain'){
			return <>&#x1f5fb;</>;//Mount Fuji
			//return <>&#127956;</>; //Mountain
		}
		else {
			return <> </>;
		}
	}
	render(){
		const value = this.props.value===null ? 'empty' : this.props.value;
		const selected = this.props.selected===null ? 'notSelected' : this.props.selected;
		
		return (
		<button className={`square ${selected} ${value}`} 
			onClick={()=>this.props.onClick()}
			data-cell-id={`${this.props.id}`}
			ref={thisElement => {
				if (!thisElement) return;
				if (this.props.selected){
					this.props.referenceUpdate(thisElement);
				}
			  }}>
			<div className={`${this.props.rotation} ${value}`}>
				{this.getGraphicForType(value)}
			</div>
			<div className={`fixedRotation ${value}`}></div>
		</button>
		);
	}
}