import React from 'react';

export default class BorderSquare extends React.Component{
	
	render(){
		const dragStatus = this.props.selected ? 'true' : 'false';
		const selected = this.props.selected===null ? 'notSelected' : this.props.selected;
		return (
		<button draggable={`${dragStatus}`}
			className={`squareBorder ${selected} ${this.props.orientation}`} 
			onClick={()=>this.props.onClick()}
			data-alibi-cell-id={`${this.props.id}-${this.props.orientation}`}>
			<span>&nbsp;</span>
		</button>
		);
	}
}