import React from 'react';
import './menu.scss';
import menu from './menu.svg';
import i18n from './i18n';

export default class Menu extends React.Component{
	constructor (props) {
		super(props);
		this.state = {
			open: false,
		};
	}
	render(){
		let entries = [];

		for (let [key, value] of Object.entries(this.props.actions)) {
			console.log(`${key}: ${value}`);
			if (value==='divider'){
				entries.push(<hr/>);
			}
			else{
				entries.push(
					<button className='item'
						data-action='action'
						key={key} 
						onClick={() => {
							value.action();
							this.toggleMenu();
							} }>
						<span>{value.title}</span>
					</button>
				);
			}
		}

		return (
			<div className='menu'>
				<button className={`mainButton ${this.state.open?'open':'close'}`} onClick={()=>this.toggleMenu()}>
					<img src={menu} width="50" height="50" alt={i18n.t('menu.icon')}/>
				</button>
				<div className={`items ${this.state.open?'show':'hidden'}`}>
					{entries}
				</div>
			</div>
		);
	}

	toggleMenu(){
		this.setState({
			open: !this.state.open,
		})
	}
}