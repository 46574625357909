import React from 'react';
import './messageBox.scss';

export default class MessageBox extends React.Component{
	
    render(){
        return (
            <div className={`warnings ${this.props.showWarning}`}>
                <span role="img" className="icon">&#x26A0;</span>
                <span className="messageText">{this.props.currentWarning}</span>
                <button className="actionButtons" onClick={()=>this.props.clearMessageCallback()}><span aria-label="delete this warning message">x</span></button>
            </div>
		);
    }
}