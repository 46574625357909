import React from 'react';
import './notification.scss';

export default class Notification extends React.Component{
	
	render(){
		return (
			<div className={`notification ${this.props.show}`}>
				<span role="img" className="icon" aria-label="Trophy">&#x1F3C6;</span>
				<span className="messageText" aria-label="winner is">{this.props.winnerIs}</span>
				<button onClick={()=>this.props.onClose()}>{this.props.closeText}</button>
			</div>
		);
	}
}