import React from 'react';
import Square from './square.js';
import BorderSquare from './borderSquare.js';
import BorderCorner from './borderCorner.js';

export default class Board extends React.Component {
	renderSquare(i) {
		return <Square 
				value={this.props.squares[i]} 
				rotation={this.props.rotation[i]}
				selected={this.props.selected===i?'selected':null}
				onClick={()=>this.props.onClick(i)}
				referenceUpdate={(el)=>this.props.referenceUpdate(el)}
				id={i}
			/>;
	}
	renderBorderSquare(i, rotation, orientation){
		return <BorderSquare
				onClick={()=>this.props.onClickBorder(i, rotation)}
				orientation={orientation}
				id={i}
			/>;
	}

	render() {
		return (
		<div className="board">
			<div className="board-row">
				<BorderCorner/>
				{this.renderBorderSquare(0, 'south', 'horizontal')}
				{this.renderBorderSquare(1, 'south', 'horizontal')}
				{this.renderBorderSquare(2, 'south', 'horizontal')}
				{this.renderBorderSquare(3, 'south', 'horizontal')}
				{this.renderBorderSquare(4, 'south', 'horizontal')}
				<BorderCorner/>
			</div>
			<div className="board-row">
				{this.renderBorderSquare(0, 'east', 'vertical')}
				{this.renderSquare(0)}
				{this.renderSquare(1)}
				{this.renderSquare(2)}
				{this.renderSquare(3)}
				{this.renderSquare(4)}
				{this.renderBorderSquare(4, 'west', 'vertical')}
			</div>
			<div className="board-row">
				{this.renderBorderSquare(5, 'east', 'vertical')}
				{this.renderSquare(5)}
				{this.renderSquare(6)}
				{this.renderSquare(7)}
				{this.renderSquare(8)}
				{this.renderSquare(9)}
				{this.renderBorderSquare(9, 'west', 'vertical')}
			</div>
			<div className="board-row">
				{this.renderBorderSquare(10, 'east', 'vertical')}
				{this.renderSquare(10)}
				{this.renderSquare(11)}
				{this.renderSquare(12)}
				{this.renderSquare(13)}
				{this.renderSquare(14)}
				{this.renderBorderSquare(14, 'west', 'vertical')}
			</div>
			<div className="board-row">
				{this.renderBorderSquare(15, 'east', 'vertical')}
				{this.renderSquare(15)}
				{this.renderSquare(16)}
				{this.renderSquare(17)}
				{this.renderSquare(18)}
				{this.renderSquare(19)}
				{this.renderBorderSquare(19, 'west', 'vertical')}
			</div>
			<div className="board-row">
				{this.renderBorderSquare(20, 'east', 'vertical')}
				{this.renderSquare(20)}
				{this.renderSquare(21)}
				{this.renderSquare(22)}
				{this.renderSquare(23)}
				{this.renderSquare(24)}
				{this.renderBorderSquare(24, 'west', 'vertical')}
			</div>
			<div className="board-row">
				<BorderCorner/>
				{this.renderBorderSquare(20, 'north', 'horizontal')}
				{this.renderBorderSquare(21, 'north', 'horizontal')}
				{this.renderBorderSquare(22, 'north', 'horizontal')}
				{this.renderBorderSquare(23, 'north', 'horizontal')}
				{this.renderBorderSquare(24, 'north', 'horizontal')}
				<BorderCorner/>
			</div>
		</div>
		);
	}
}
