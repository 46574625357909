import React from 'react';
import arrow from './arrow.svg';
import { withTranslation } from 'react-i18next';
import i18n from './i18n';

class ActionInsertPushWizzard extends React.Component{
	
	render(){
		return(<div className={`selectedActions ${this.props.className}`} style={this.props.actionStyle}>
			<div className='row'>
				<div className="hiddenPlaceHolder popupButton"></div>
					<button onClick={()=>this.props.insertPushAction('north')} 
						className={`insertPush ${this.props.actions.includes('insertPushNorth')?'active':'invisible'} popupButton`}
						data-action="insertPushNorth"
						title={i18n.t('action.insertPush.north')}>
						<span role="img" aria-label={i18n.t('action.insertPush.north')}>
							<img  className="north" src={arrow} width="50" height="50" alt={i18n.t('action.insertPush.north')}/></span>
					</button>
					<div className="hiddenPlaceHolder popupButton"></div>
				</div>
				<div className='row'>
					<button onClick={()=>this.props.insertPushAction('west')} 
						className={`insertPush ${this.props.actions.includes('insertPushWest')?'active':'invisible'} popupButton`}
						data-action="insertPushWest"
						title={i18n.t('action.insertPush.west')}>
						<span role="img" aria-label={i18n.t('action.insertPush.west')}>
							<img  className="west" src={arrow} width="50" height="50" alt={i18n.t('action.insertPush.west')}/></span>
					</button>
					<div className="hiddenPlaceHolder popupButton"></div>
					<button onClick={()=>this.props.insertPushAction('east')} 
						className={`insertPush ${this.props.actions.includes('insertPushEast')?'active':'invisible'} popupButton`}
						data-action="insertPushEast"
						title={i18n.t('action.insertPush.east')}>
						<span role="img" aria-label={i18n.t('action.insertPush.east')}>
							<img  className="east" src={arrow} width="50" height="50" alt={i18n.t('action.insertPush.east')}/></span>
					</button>
				</div>
				<div className="row">
					<div className="hiddenPlaceHolder popupButton"></div>
					<button onClick={()=>this.props.insertPushAction('south')} 
						className={`insertPush ${this.props.actions.includes('insertPushSouth')?'active':'invisible'} popupButton`}
						data-action="insertPushSouth"
						title={i18n.t('action.insertPush.south')}>
						<span role="img" aria-label={i18n.t('action.insertPush.south')}>
							<img  className="south" src={arrow} width="50" height="50" alt={i18n.t('action.insertPush.south')}/></span>
					</button>
					<div className="hiddenPlaceHolder popupButton"></div>
				</div>
		</div>);
	}
}

export default withTranslation('translations')(ActionInsertPushWizzard);
