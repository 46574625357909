import React from 'react';
import arrow from './arrow.svg';
import { withTranslation } from 'react-i18next';
import i18n from './i18n';

class MainActionWizzard extends React.Component{
	
	render(){
		return(<div className={`selectedActions ${this.props.className}`} style={this.props.actionStyle}>
			<div className='row'>
				<button onClick={()=>this.props.insertAction(null)} 
					className={`animalsLeft ${(this.props.actions.includes('insert')||this.props.actions.includes('insertPush')) && this.props.rhinoIsNext?'active':'hide'} popupButton`}
					data-action="insertRhino"
					title={i18n.t('action.insert.rhino', {count: this.props.rhinosLeft, total: this.props.total})}>
					<span className="leftCounter">{this.props.rhinosLeft}</span>
					<span className="rhino" role="img" aria-label={i18n.t('action.insert.rhino', {count: this.props.rhinosLeft, total: this.props.total})}>&#x1F98F;</span>
				</button>
				<button onClick={()=>this.props.insertAction(null)} 
					className={`animalsLeft ${(this.props.actions.includes('insert')||this.props.actions.includes('insertPush')) && !this.props.rhinoIsNext?'active':'hide'} popupButton`}
					data-action="insertElephant"
					title={i18n.t('action.insert.elephant', {count: this.props.elephantsLeft, total: this.props.total})}>
					<span className="leftCounter">{this.props.elephantsLeft}</span>
					<span className="elephant" role="img" aria-label={i18n.t('action.insert.elephant', {count: this.props.elephantsLeft, total: this.props.total})}>&#x1f418;</span>
				</button>
				<div className={`placeHolder popupButton  ${(!this.props.actions.includes('insert')&&!this.props.actions.includes('insertPush'))?'invisible':'hide'}`}></div>
				<button onClick={()=>this.props.moveAction('north')} 
					className={`movement ${this.props.actions.includes('moveNorth') || this.props.actions.includes('pushNorth')?'active':'invisible'} popupButton`}
					data-action="moveNorth"
					title={i18n.t('action.move.north')}>
					<span role="img" aria-label={i18n.t('action.move.north')}>
						<img  className="north" src={arrow} width="50" height="50" alt={i18n.t('action.move.north')}/></span>
				</button>
				<button onClick={()=>this.props.removeAction()}
					className={`${this.props.actions.includes('remove')?'active':'invisible'} popupButton trash`}
					data-action="remove"
					title={i18n.t('action.remove')}>
					<span role="img" aria-label={i18n.t('action.remove')}>&#x1F5D1;</span>
				</button>
			</div>
			<div className='row'>
				<button onClick={()=>this.props.moveAction('west')} 
					className={`movement ${this.props.actions.includes('moveWest') || this.props.actions.includes('pushWest')?'active':'invisible'} popupButton`}
					data-action="moveWest"
					title={i18n.t('action.move.west')}>
					<span role="img" aria-label={i18n.t('action.move.west')}>
						<img  className="west" src={arrow} width="50" height="50" alt={i18n.t('action.move.west')}/></span>
				</button>
				<button onClick={()=>this.props.deselectAction()} 
					className='popupButton hiddenPlaceHolder'
					data-action="deselect"
					title={i18n.t('action.deselect')}>
					<span role="img" aria-label={i18n.t('action.deselect')}>&nbsp;</span>
				</button>
				<button onClick={()=>this.props.moveAction('east')} 
					className={`movement ${this.props.actions.includes('moveEast') || this.props.actions.includes('pushEast')?'active':'invisible'} popupButton`}
					data-action="moveEast"
					title={i18n.t('action.move.east')}>
					<span role="img" aria-label={i18n.t('action.move.east')}>
						<img  className="east" src={arrow} width="50" height="50" alt={i18n.t('action.move.east')}/></span>
				</button>
			</div>
			<div className="row">
				<div className="hiddenPlaceHolder popupButton"></div>
				<button onClick={()=>this.props.moveAction('south')} 
					className={`movement ${this.props.actions.includes('moveSouth') || this.props.actions.includes('pushSouth')?'active':'invisible'} popupButton`}
					data-action="moveSouth"
					title={i18n.t('action.move.south')}>
					<span role="img" aria-label={i18n.t('action.move.south')}>
						<img  className="south" src={arrow} width="50" height="50" alt={i18n.t('action.move.south')}/></span>
				</button>
				<button onClick={()=>this.props.rotateAction()}
					className={`${this.props.actions.includes('rotate')?'active':'invisible'} popupButton`}
					data-action="rotate"
					title={i18n.t('action.rotate.open')}>
					<span role="img" aria-label={i18n.t('action.rotate.open')}>&#x21ba;</span>
				</button>
			</div>
		</div>);
	}
}

export default withTranslation('translations')(MainActionWizzard);
