import React from 'react';
import arrow from './arrow.svg';
import { withTranslation } from 'react-i18next';
import i18n from './i18n';

class ActionRotationWizzard extends React.Component{
	
	render(){
		return(<div className={`selectedActions ${this.props.className}`} style={this.props.actionStyle}>
			<div className='row'>
				<div className="hiddenPlaceHolder popupButton"></div>
					<button onClick={()=>this.props.rotateAction('north')} 
						className={`rotation active popupButton`}
						data-action="rotateNorth"
						title={i18n.t('action.rotate.north')}>
						<span role="img" aria-label={i18n.t('action.rotate.north')}>
							<img  className="north" src={arrow} width="50" height="50" alt={i18n.t('action.rotate.north')}/></span>
					</button>
					<div className="hiddenPlaceHolder popupButton"></div>
				</div>
				<div className='row'>
					<button onClick={()=>this.props.rotateAction('west')} 
						className={`rotation active popupButton`}
						data-action="rotateWest"
						title={i18n.t('action.rotate.west')}>
						<span role="img" aria-label={i18n.t('action.rotate.west')}>
							<img  className="west" src={arrow} width="50" height="50" alt={i18n.t('action.rotate.west')}/></span>
					</button>
					<div className="hiddenPlaceHolder popupButton"></div>
					<button onClick={()=>this.props.rotateAction('east')} 
						className={`rotation active popupButton`}
						data-action="rotateEast"
						title={i18n.t('action.rotate.east')}>
						<span role="img" aria-label={i18n.t('action.rotate.east')}>
							<img  className="east" src={arrow} width="50" height="50" alt={i18n.t('action.rotate.east')}/></span>
					</button>
				</div>
				<div className="row">
					<div className="hiddenPlaceHolder popupButton"></div>
					<button onClick={()=>this.props.rotateAction('south')} 
						className={`rotation active popupButton`}
						data-action="rotateSouth"
						title={i18n.t('action.rotate.south')}>
						<span role="img" aria-label={i18n.t('action.rotate.south')}>
							<img  className="south" src={arrow} width="50" height="50" alt={i18n.t('action.rotate.south')}/></span>
					</button>
					<div className="hiddenPlaceHolder popupButton"></div>
				</div>
		</div>);
	}
}

export default withTranslation('translations')(ActionRotationWizzard);
